import { IllustrationRating } from '../types';
import { getRequestOptions } from './util';
import { INSPECTOR_BASE_PATH } from './util/constants';

/**
 * Adds a review to the illustration
 * Creates an illustration review event that is sent to Illustrator Consumer for persist. Sets review status of corresponding illustration REVIEWED.
 *
 * POST /api/review/illustration/:illustrationId/rating
 *
 * @returns Promise<QueueAge>
 * [documentation]{@link https://github.com/eyeem/inspector/tree/integration/app/com/eyeem/inspector/controllers/docs/illustrations#apireviewillustrationillustrationidrating}
 */
export const addReviewToIllustration = (
  illustrationId: number | string,
  rating: IllustrationRating
) => {
  const body = JSON.stringify({ review: rating });
  return fetch(`${INSPECTOR_BASE_PATH.REVIEW}${illustrationId}/rating`, {
    credentials: 'include',
    ...getRequestOptions('POST'),
    body,
  })
    .then((response) => response.json())
    .catch((error) => console.error(error));
};

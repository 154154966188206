import { Fragment } from 'react';
import { Box, Text } from '@eyeem-ui/atoms';
import { Modal, ModalTitle } from '@eyeem-ui/organisms';
import styled from '@emotion/styled';

import { shortcuts as shortcutsList } from './shortcuts';

const StyledModal = styled(Modal)`
  min-width: 800px;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 40% 40%;
  column-gap: 20%;
  row-gap: ${({ theme }) => theme.space[2]}px;
`;

const StyledCode = styled.code`
  color: ${({ theme }) => theme.colors.grey00};
  background-color: ${({ theme }) => theme.colors.aqua60};
  border: 1px solid ${({ theme }) => theme.colors.aqua40};
  border-radius: ${({ theme }) => theme.radii[1]};
  padding: 0 ${({ theme }) => theme.space[1]}px;
`;

const ShortcutModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const ShortCuts = () =>
    Object.entries(shortcutsList)
      .map((entry) => ({
        title: entry[0],
        shortcuts: entry[1],
      }))
      .map(({ title, shortcuts }) => (
        <Box key={title}>
          <Text variant="title5">{title}</Text>
          <Grid>
            {shortcuts.map(({ key, description }) => (
              <Fragment key={key}>
                <Text>
                  <StyledCode>{key}</StyledCode>
                </Text>
                <Text>{description}</Text>
              </Fragment>
            ))}
          </Grid>
        </Box>
      ));

  return (
    <StyledModal isShown={isOpen} close={close} onDismiss={close}>
      <Box p="3" pb="4">
        <Box pb="2">
          <ModalTitle text="Shortcuts" />
        </Box>
        <Grid>{ShortCuts()}</Grid>
      </Box>
    </StyledModal>
  );
};

export default ShortcutModal;

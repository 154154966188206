import { createReviewBatch } from '@api/createReviewBatch';
import { getIllustrationBatchById } from '@api/getIllustrationBatchById';
import { Fetch } from 'auth/types';
import { ReviewBatchEnriched } from '../../types';

export type GetReviewBatchPayload = { queueId?: number; batchId?: number };

const getReviewBatch =
  (payload: GetReviewBatchPayload) =>
  async (fetch: Fetch): Promise<ReviewBatchEnriched | void> => {
    try {
      let batch;
      const { batchId, queueId } = payload;
      if (batchId) {
        batch = await getIllustrationBatchById({ batchId })(fetch);
      } else if (queueId) {
        batch = await createReviewBatch({ queueId })(fetch);
      }
      return batch;
    } catch (error) {
      console.log(error);
    }
  };

export default getReviewBatch;

import { IllustrationRating } from '@type';
import { KEYCODES } from './globals';

export const shortcutKeyToRating = (
  code: KEYCODES
): IllustrationRating | undefined => {
  switch (code) {
    case KEYCODES.DIGIT0:
    case KEYCODES.NUMPAD0:
      return IllustrationRating.watermarks;

    case KEYCODES.DIGIT1:
    case KEYCODES.NUMPAD1:
    case KEYCODES.DIGIT2:
    case KEYCODES.NUMPAD2:
      return IllustrationRating.premium;

    case KEYCODES.DIGIT3:
    case KEYCODES.NUMPAD3:
    case KEYCODES.DIGIT4:
    case KEYCODES.NUMPAD4:
      return IllustrationRating.essential;

    case KEYCODES.DIGIT7:
    case KEYCODES.NUMPAD7:
      return IllustrationRating.similarity;

    case KEYCODES.DIGIT8:
    case KEYCODES.NUMPAD8:
      return IllustrationRating.lowCommercialValue;

    case KEYCODES.DIGIT9:
    case KEYCODES.NUMPAD9:
      return IllustrationRating.termsOfServiceViolation;
    case KEYCODES.E:
      return IllustrationRating.onHold;
    default:
  }
};

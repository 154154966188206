export type CoverPhoto = {
  blacklisted: boolean;
  file_id: string;
  height: number;
  hidden: boolean;
  id: string;
  photoUrl: string;
  photoUrlPublic: string;
  submittedToMarket: boolean;
  type: string;
  updated: string;
  webUrl: string;
  width: number;
};

export type Author = {
  blacklisted: boolean;
  blocked: boolean;
  coverPhoto: CoverPhoto | null;
  description: string | null;
  file_id: string | null;
  follower: boolean;
  following: boolean;
  fullname: string;
  hidden: boolean;
  homepageUrl: string | null;
  id: string;
  marketTotals: MarketTotals;
  nickname: string;
  photoUrl: string;
  restricted: boolean;
  thumbUrl: string;
  totalFollowers: number;
  totalFriends: number;
  totalLikedAlbums: number;
  totalLikedPhotos: number;
  totalPhotos: number;
  webUrl: string;
};

// https://illustrator-api.eyeem.com/docs/#/illustrations/schemas
export type IllustrationBasic = {
  caption: string;
  creator: {
    id: number | string;
  };
  categories?: Category[];
  createdAt: string;
  height: number;
  id: string;
  isLicensable: boolean;
  keywords?: string[];
  previewUrl?: string;
  review?: Review | IllustrationRating;
  styles?: Style[];
  thumbUrl?: string;
  width: number;
};

export type Review = {
  status: string;
  value: IllustrationRating;
  description: null;
};

export type IllustrationEnriched = Omit<IllustrationBasic, 'creator'> & {
  creator: Author;
};

export enum IllustrationRating {
  // accepted for market
  premium = 'PREMIUM',
  essential = 'ESSENTIAL',

  // rejected from market
  lowCommercialValue = 'LOW_COMMERCIAL_VALUE',
  termsOfServiceViolation = 'TERMS_OF_SERVICE_VIOLATION',
  similarity = 'SIMILARITY',
  watermarks = 'WATERMARKS',

  // on hold
  onHold = 'ON_HOLD',
}

export type MarketTotals = {
  commercial: number;
  editorial: number;
  getty: number;
  onHold: number;
  partner: number;
};

export type ReviewBatchBasic = {
  createdAt: string;
  id: number;
  illustrations: string[];
  queue: {
    id: number;
    name: string;
    isVisible: boolean;
    isDeleted: boolean;
  };
  reviewerId: number;
};

export type ReviewBatchSingle = Omit<ReviewBatchBasic, 'illustrations'> & {
  illustrationId: string;
};

export type ReviewBatchEnriched = Omit<ReviewBatchBasic, 'illustrations'> & {
  illustrations: IllustrationEnriched[];
};

export enum ReviewStatus {
  accepted = 'ACCEPTED',
  rejected = 'REJECTED',
  hold = 'HOLD',
}

// https://illustrator-api.eyeem.com/docs/#/illustrations/schemas
export type Style = {
  key: string;
  value: string;
};

export type Category = Style;

import { useEffect, useMemo, useState } from 'react';
import { useTable, Column, useSortBy } from 'react-table';
import { Button, Flex, IconButton, Text } from '@eyeem-ui/atoms';
import { Chevron } from '@eyeem-ui/icons';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import {
  getReviewQueues,
  getReviewQueuesAge,
  QueuesState,
  QueuesAgeState,
} from '@api';
import { useAuthAPI } from '../../auth/context';

const StyledTable = styled.table`
  table-layout: fixed;
  width: 100%;
  max-width: 800px;
  border-collapse: collapse;
  border-radius: ${({ theme }) => theme.radii[2]};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.ghost60}};

  th {
    text-align: left;
  }

  th,
  td {
    padding: ${({ theme }) => `${theme.space[2]}px`};
  }

  tbody tr {
    border-top: 1px solid ${({ theme }) => theme.colors.ghost60}};
  }
`;

interface Row {
  name: string;
  size: number;
  waitTime: string | null;
  startReviewing: JSX.Element;
}

const Queues = () => {
  const [queues, setQueues] = useState<QueuesState>();
  const [queuesAge, setQueuesAge] = useState<QueuesAgeState>();
  const { withAuthorizedInterceptor } = useAuthAPI();

  const columns = useMemo<Column<Row>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Size',
        accessor: 'size',
      },
      {
        Header: 'Wait time',
        accessor: 'waitTime',
      },
      {
        Header: 'Start reviewing',
        accessor: 'startReviewing',
        disableSortBy: true,
      },
    ],
    []
  );

  const tableRows = useMemo<Row[]>(
    () =>
      queues
        ? queues.map((queue) => {
            const isDisabled = queue.count === 0;
            return {
              name: queue.name,
              size: queue.count,
              waitTime: queuesAge
                ? `${queuesAge[queue.name].ageInDays} days`
                : null,
              startReviewing: (
                <Link to={isDisabled ? '#' : `review?queue=${queue.id}`}>
                  <Button disabled={isDisabled}>Start</Button>
                </Link>
              ),
            };
          })
        : [],
    [queues, queuesAge]
  );

  const tableInstance = useTable(
    {
      columns,
      data: tableRows,
      initialState: {
        sortBy: [
          { id: 'waitTime', desc: false },
          { id: 'size', desc: false },
        ],
      },
    },
    useSortBy
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  useEffect(() => {
    withAuthorizedInterceptor(getReviewQueues).then((response) => {
      setQueues(response);
    });
  }, []);

  useEffect(() => {
    withAuthorizedInterceptor(getReviewQueuesAge).then((response) => {
      setQueuesAge(response);
    });
  }, []);

  return (
    <StyledTable {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              return (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <Flex justifyContent="space-between">
                    <Text bold variant="title5">
                      {column.render('Header')}
                    </Text>
                    {column.canSort && (
                      <IconButton
                        icon={
                          column.isSorted
                            ? column.isSortedDesc
                              ? () => <Chevron rotate="up" />
                              : () => <Chevron rotate="down" />
                            : () => <Chevron rotate="right" />
                        }
                      />
                    )}
                  </Flex>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>
                  <Text>{cell.render('Cell')}</Text>
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

export default Queues;

import { memo, useEffect, useRef } from 'react';

export type ScrollIntoViewProps = {
  shouldScroll: boolean;
  children: JSX.Element;
  onScroll: () => void;
} & ScrollIntoViewOptions;

function ScrollIntoView({
  shouldScroll,
  children,
  block,
  onScroll,
}: ScrollIntoViewProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({
        block: block,
      });
      onScroll();
    }
  }, [shouldScroll]);

  return <div ref={ref}>{children}</div>;
}

export default memo(ScrollIntoView);

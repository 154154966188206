import { AuthState } from './types';

enum AuthActions {
  'AUTH_SET_AUTHORIZED',
}

type Action = {
  action: AuthActions;
  parameter: any;
};

export const setIsAuthorized = (isAuthorized: boolean): Action => ({
  action: AuthActions.AUTH_SET_AUTHORIZED,
  parameter: { isAuthorized },
});

export const authStateReducer = (state: AuthState, action: Action) => {
  switch (action.action) {
    case AuthActions.AUTH_SET_AUTHORIZED: {
      return { ...state, isAuthorized: action.parameter.isAuthorized };
    }
    default:
      return state;
  }
};

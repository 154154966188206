import { IllustrationBasic, IllustrationEnriched } from '@type';
import { Author } from '@type';
import { INSPECTOR_BASE_PATH } from './constants';
import { getUserById } from './getUserById';
import { getRequestOptions } from './requestOptions';
import { isBasicIllustration } from '@type/typeguards/isIllustration';

/**
 * We're enriching the illustration with the creator user data
 * We are using `reduce` as we do not want to include empty data and `filter` causes issues (typescript can't properly infer the type at time of writing)
 */
const enrichIllustrationsWithCreator = async (arg: IllustrationBasic[]) => {
  const authorsCache: Record<string | number, Author> = {};
  return await arg.reduce(async (acc, cur) => {
    if (authorsCache?.[cur.creator.id] === undefined) {
      const user = await getUserById(
        typeof cur.creator.id === 'number'
          ? cur.creator.id
          : parseInt(cur.creator.id, 10)
      );
      if (user) {
        const enrichedIllustration = {
          ...cur,
          creator: user,
        } as IllustrationEnriched;

        return [...(await acc), { ...enrichedIllustration }];
      }
      return acc;
    } else {
      return [
        ...(await acc),
        {
          ...cur,
          creator: authorsCache[cur.creator.id],
        },
      ];
    }
  }, Promise.resolve([] as IllustrationEnriched[]));
};

export const getIllustrationsById = async (ids: (string | number)[]) => {
  try {
    const body = JSON.stringify({
      illustrationIds: ids,
    });
    const response = await fetch(`${INSPECTOR_BASE_PATH.ILLUSTRATOR}`, {
      credentials: 'include',
      ...getRequestOptions('POST'),
      body,
    });

    const { items } = await response.json();
    const illustrations = items.filter(isBasicIllustration);

    const illustrationsWithCreator =
      enrichIllustrationsWithCreator(illustrations);
    return illustrationsWithCreator;
  } catch (error) {
    return console.error(error);
  }
};

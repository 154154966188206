// A page that displays the currently deployed inspector version. Useful for checking if the deployment is up to date
import { Flex, Text } from '@eyeem-ui/atoms';

const { version } = require('../../package.json');

export default function Version() {
  return (
    <Flex justifyContent="center" m={3}>
      <Text bold variant="title5">
        Version: {version}
      </Text>
    </Flex>
  );
}

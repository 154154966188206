import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Global } from '@emotion/react';
import { globalStyles, ThemeProvider, theme, useTheme } from '@eyeem-ui/theme';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { KEYCODES } from '@util/globals';

const makeTheme = <T extends Theme>(t: T) => t;
const typedTheme = makeTheme(theme);

export type ExactTheme = typeof typedTheme;
export type Theme = 'light' | 'dark';
const Index = () => {
  const [colorMode, setColorMode] = useTheme();

  useEffect(() => {
    const toggleColorMode = (event: KeyboardEvent) => {
      if (
        event.code === KEYCODES.PERIOD ||
        event.code === KEYCODES.NUMPADDECIMAL
      ) {
        setColorMode(colorMode === 'light' ? 'dark' : 'light');
      }
    };

    window.addEventListener('keydown', toggleColorMode);

    return () => {
      window.removeEventListener('keydown', toggleColorMode);
    };
  }, [colorMode, setColorMode]);

  return (
    <React.StrictMode>
      <ThemeProvider mode={colorMode}>
        <Global styles={globalStyles} />
        <App />
      </ThemeProvider>
    </React.StrictMode>
  );
};
ReactDOM.render(<Index />, document.getElementById('root') as HTMLElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { KEYCODES } from './globals';

export const isRatingShortcut = (code: string): code is KEYCODES => {
  switch (code) {
    case KEYCODES.DIGIT0:
    case KEYCODES.DIGIT1:
    case KEYCODES.DIGIT2:
    case KEYCODES.DIGIT3:
    case KEYCODES.DIGIT4:
    case KEYCODES.DIGIT5:
    case KEYCODES.DIGIT7:
    case KEYCODES.DIGIT8:
    case KEYCODES.DIGIT9:
    case KEYCODES.NUMPAD0:
    case KEYCODES.NUMPAD1:
    case KEYCODES.NUMPAD2:
    case KEYCODES.NUMPAD3:
    case KEYCODES.NUMPAD4:
    case KEYCODES.NUMPAD5:
    case KEYCODES.NUMPAD7:
    case KEYCODES.NUMPAD8:
    case KEYCODES.NUMPAD9:
    case KEYCODES.E:
      return true;
    default:
      return false;
  }
};

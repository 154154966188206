import { useEffect, useState } from 'react';
import { ThemeProvider } from '@eyeem-ui/theme';
import { Box, Flex, Logo, Text, IconButton } from '@eyeem-ui/atoms';
import styled from '@emotion/styled';
import { NAVBAR_HEIGHT, KEYCODES } from '@util/globals';
import ShortcutModal from '@components/shortcutModal';
import { QuestionCircle } from '@eyeem-ui/icons';
import { Link } from 'react-router-dom';

const StyledBox = styled(Box)`
  height: ${NAVBAR_HEIGHT}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ghost40};
`;

const Navbar = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  useEffect(() => {
    const openShortcutModal = (event: KeyboardEvent) => {
      if (event.code === KEYCODES.H) {
        setModalIsOpen(!modalIsOpen);
      }
    };

    window.addEventListener('keydown', openShortcutModal);

    return () => {
      window.removeEventListener('keydown', openShortcutModal);
    };
  }, [modalIsOpen]);

  return (
    <ThemeProvider>
      <StyledBox bg="grey40">
        <Flex p="2" justifyContent="space-between" alignItems="center">
          <Link to="/">
            <Flex alignItems="center">
              <Logo />
              <Box ml="2">
                <Text>Inspector Next-Gen</Text>
              </Box>
            </Flex>
          </Link>
          <IconButton
            icon={QuestionCircle}
            onClick={() => setModalIsOpen(!modalIsOpen)}
          />
        </Flex>
      </StyledBox>

      <ShortcutModal isOpen={modalIsOpen} close={() => setModalIsOpen(false)} />
    </ThemeProvider>
  );
};

export default Navbar;

import { getRequestOptions } from './util';
import { INSPECTOR_BASE_PATH } from './util/constants';
import { Fetch } from '../auth/types';

type QueueAge = { oldestIllustrationWaitingSince: Date; ageInDays: number };

export type QueuesAgeState = Record<string, QueueAge>;

/**
 * Returns the available reviewing queues for illustrations along with the age of the oldest illustration queued for review.
 * GET /api/review/illustration/queues/age
 *
 *  @returns {Promise<QueueAge>}
 *
 * [documentation]{@link https://github.com/eyeem/inspector/blob/integration/app/com/eyeem/inspector/controllers/docs/illustrations/README.md#apireviewillustrationqueues}
 */
export const getReviewQueuesAge = (fetch: Fetch): Promise<QueuesAgeState> => {
  return fetch(`${INSPECTOR_BASE_PATH.REVIEW}queues/age`, getRequestOptions())
    .then((response) => response.json())
    .catch((error) => console.error(error));
};

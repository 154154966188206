import styled from '@emotion/styled';
import { Check, Disable, Danger } from '@eyeem-ui/icons';
import { IllustrationRating, ReviewStatus } from '@type';
import { ratingToStatus } from '@util';

const StyledReviewStatusIconWrapper = styled.div<{ status: ReviewStatus }>`
  position: absolute;
  top: ${({ theme }) => theme.space[2]}px;
  right: ${({ theme }) => theme.space[2]}px;
  background-color: ${({ theme, status }) => {
    switch (status) {
      case ReviewStatus.accepted: {
        return theme.colors.aqua40;
      }
      case ReviewStatus.rejected: {
        return theme.colors.red40;
      }
      case ReviewStatus.hold: {
        return theme.colors.grey40;
      }
    }
  }};
  padding: ${({ theme }) => theme.space[1]}px;
  border-radius: ${({ theme }) => theme.radii[1]};
`;

type StatusIconProps = {
  illustrationRating?: IllustrationRating;
};

function StatusIcon({ illustrationRating }: StatusIconProps) {
  if (illustrationRating === undefined) {
    return null;
  }

  const status = ratingToStatus(illustrationRating);

  if (status) {
    switch (status) {
      case ReviewStatus.accepted: {
        return (
          <StyledReviewStatusIconWrapper status={status}>
            <Check color="white" />
          </StyledReviewStatusIconWrapper>
        );
      }
      case ReviewStatus.rejected: {
        return (
          <StyledReviewStatusIconWrapper status={status}>
            <Disable color="white" />
          </StyledReviewStatusIconWrapper>
        );
      }
      case ReviewStatus.hold: {
        return (
          <StyledReviewStatusIconWrapper status={status}>
            <Danger color="white" />
          </StyledReviewStatusIconWrapper>
        );
      }
    }
  }
  return null;
}
export default StatusIcon;

//This should probably live in a different package. But it is only used by auth right now
import { Fetch, ResponseInterceptor } from './types';

//for reference
export const loggingInterceptor: ResponseInterceptor = (response) =>
  response.then((r) => {
    console.debug(r);
    return r;
  });

export const makeResponseInterceptor =
  (
    predicate: (r: Response) => boolean,
    handler: (response: Response) => void
  ): ResponseInterceptor =>
  (response) =>
    response.then((r) => {
      if (predicate(r)) {
        handler(r);
      }
      return r;
    });

export const makeFetcher: (...interceptors: ResponseInterceptor[]) => Fetch = (
  ...interceptors: ResponseInterceptor[]
) => {
  return (input: RequestInfo | URL, init?: RequestInit) => {
    return interceptors.reduceRight((previous, current) => {
      return current(previous);
    }, fetch(input, init));
  };
};

export const shortcuts: Record<string, { key: string; description: string }[]> =
  {
    General: [
      { key: 'SPACE', description: 'Open full screen mode' },
      { key: 'CTRL + ENTER', description: 'Fetch new batch' },
      {
        key: 'P',
        description: 'Open the current user profile URL with /illustrations',
      },
      { key: 'H ', description: 'Show modal with shortcuts' },
      { key: '.', description: 'Toggle light/dark mode' },
    ],
    Rating: [
      { key: '1', description: 'Premium' },
      { key: '2', description: 'Premium' },
      { key: '3', description: 'Essential' },
      { key: '4', description: 'Essential' },
      { key: '7', description: 'Similarity' },
      { key: '8', description: 'Low Buyer Demand' },
      { key: '9', description: 'Terms of Service' },
      { key: '0', description: 'Watermarks' },
      { key: 'E', description: 'On Hold' },
    ],
  };

import { IllustrationRating, ReviewStatus } from '../types';

export const ratingToStatus = (
  rating: IllustrationRating
): ReviewStatus | void => {
  switch (rating) {
    case IllustrationRating.essential:
    case IllustrationRating.premium: {
      return ReviewStatus.accepted;
    }

    case IllustrationRating.lowCommercialValue:
    case IllustrationRating.termsOfServiceViolation:
    case IllustrationRating.similarity:
    case IllustrationRating.watermarks: {
      return ReviewStatus.rejected;
    }

    case IllustrationRating.onHold: {
      return ReviewStatus.hold;
    }

    default: {
      console.error('Invalid rating:', rating);
    }
  }
};

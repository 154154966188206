import { createContext, useContext, useMemo, useReducer } from 'react';
import { AuthAPI, AuthState, Fetch, ResponseInterceptor } from './types';
import { authStateReducer, setIsAuthorized } from './reducer';
import {
  loggingInterceptor,
  makeFetcher,
  makeResponseInterceptor,
} from './fetch';

const AuthStateContext = createContext<AuthState>({} as AuthState);
const AuthAPIContext = createContext<AuthAPI>({} as AuthAPI);

const initialState: AuthState = {
  isAuthorized: true, //lets be optimistic
};

export const AuthContextProvider = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const [state, dispatch] = useReducer(authStateReducer, initialState);
  const authInterceptor: ResponseInterceptor = makeResponseInterceptor(
    () => true,
    (response) => dispatch(setIsAuthorized(response.status !== 401))
  );

  const api = useMemo<AuthAPI>(() => {
    const authorized = makeFetcher(authInterceptor, loggingInterceptor);
    function withAuthorizedInterceptor<T>(f: (fetch: Fetch) => T) {
      return f(authorized);
    }
    return { authorized, withAuthorizedInterceptor };
  }, []);

  return (
    <AuthAPIContext.Provider value={api}>
      <AuthStateContext.Provider value={state}>
        {children}
      </AuthStateContext.Provider>
    </AuthAPIContext.Provider>
  );
};

export const useAuthState = () => useContext(AuthStateContext);
export const useAuthAPI = () => useContext(AuthAPIContext);

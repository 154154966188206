import { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Input } from '@eyeem-ui/atoms';

const SingleAssetReview = () => {
  const [assetId, setAssetId] = useState('');
  const navigate = useNavigate();

  const onSubmit = () => {
    navigate(`/review/illustration/${assetId}`);
  };

  return (
    <Box mt="4">
      <form onSubmit={onSubmit}>
        <Flex alignItems="end">
          <Box flex="1 1 auto">
            <Input
              label="Look for an asset by ID:"
              type="number"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setAssetId(event.target.value)
              }
            />
          </Box>
          <Box flex="0 0 auto" pl="1" mb="2">
            <Button type="submit">Go</Button>
          </Box>
        </Flex>
      </form>
    </Box>
  );
};

export default SingleAssetReview;

import { ReviewBatchBasic, ReviewBatchEnriched } from '../types';
import { getRequestOptions } from './util';
import { INSPECTOR_BASE_PATH } from './util/constants';
import { Fetch } from '../auth/types';
import { isBasicReviewBatch } from '@type/typeguards';
import { getIllustrationsById } from './util/getIllustrationById';

/**
 * Fetch a single illustration batch by ID.
 * Sets review status of corresponding illustrations IN_REVIEW.
 *
 * GET /api/review/illustration/batches/:id
 *
 * @returns {Promise<ReviewBatch>}
 * [documentation]{@link https://github.com/eyeem/inspector/blob/integration/app/com/eyeem/inspector/controllers/docs/illustrations/README.md#apireviewillustrationbatchesid}
 */
export const getIllustrationBatchById =
  ({ batchId }: { batchId: number }) =>
  async (fetch: Fetch): Promise<ReviewBatchEnriched | void> => {
    try {
      const response = await fetch(
        `${INSPECTOR_BASE_PATH.REVIEW}batches/${batchId}`,
        getRequestOptions()
      );

      const reviewBatch: ReviewBatchBasic = await response.json();
      if (!isBasicReviewBatch(reviewBatch)) {
        return console.error('not valid reviewBatch type', reviewBatch);
      }

      const illustrations = await getIllustrationsById(
        reviewBatch.illustrations
      );
      if (illustrations) {
        return { ...reviewBatch, illustrations: [...illustrations] };
      }
    } catch (error) {
      console.error(error);
    }
  };

import { getRequestOptions } from './util';
import { INSPECTOR_BASE_PATH } from './util/constants';

/**
 * Put an illustration on the On Hold queue
 *
 * Creates an illustration review event that is sent to Illustrator Consumer for
 * persist. Moves the corresponding illustration to the On Hold queue with
 * status QUEUED.
 *
 * PUT /api/review/illustration/:illustrationId/onhold
 *
 * [documentation]{@link https://github.com/eyeem/inspector/tree/integration/app/com/eyeem/inspector/controllers/docs/illustrations#put-apireviewillustrationillustrationidonhold}
 */
export const addIllustrationToOnHoldQueue = (
  illustrationId: number | string
) => {
  return fetch(`${INSPECTOR_BASE_PATH.REVIEW}${illustrationId}/onhold`, {
    credentials: 'include',
    ...getRequestOptions('PUT'),
  })
    .then((response) => response.json())
    .catch((error) => console.error(error));
};

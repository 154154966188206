import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { Box, Button, Flex, Text } from '@eyeem-ui/atoms';
import { NAVBAR_HEIGHT } from '@util/globals';

const StyledFlex = styled(Flex)`
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
`;

const NotFound = ({ message }: { message?: string }) => {
  return (
    <StyledFlex
      alignItems="center"
      justifyContent="center"
      flexDirection="column">
      <Text variant="title3" bold>
        {message || "We couldn't find what you're looking for"}
      </Text>
      <Box mt="3">
        <Link to="/">
          <Button>Back to home</Button>
        </Link>
      </Box>
    </StyledFlex>
  );
};

export default NotFound;

// using macro to use component selectors with CRA https://github.com/emotion-js/emotion/issues/1305#issuecomment-646479589
import styled from '@emotion/styled/macro';
import { useEffect, useReducer, useCallback, useState, useRef } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Flex, Spinner } from '@eyeem-ui/atoms';

import { IllustrationEnriched, IllustrationRating } from '@type';

import Sidebar from './sidebar';
import FullPagePreview from '@components/fullPagePreview';
import { NAVBAR_HEIGHT } from '@util/globals';
import ReviewGridItem from './ReviewGridItem';
import { useAuthAPI } from '../../auth/context';
import {
  reviewGridReducer,
  ReviewGridState,
  setIsScrollNeeded,
  setItems,
  setSelected,
  ReviewGridStateActions,
  setFullscreenMode,
} from '@components/reviewGrid/reducer';
import ScrollIntoView from '@components/reviewGrid/ScrollIntoView';
import getReviewBatch, {
  GetReviewBatchPayload,
} from '@api/util/getReviewBatch';

const StyledGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(${({ cols }: { cols: number }) => cols}, 1fr);
  grid-template-rows: repeat(${({ cols }: { cols: number }) => cols}, 1fr);
  gap: ${({ theme }) => theme.space[3]}px;
  height: 100%;
  flex-grow: 1;
  overflow: scroll;

  &:focus {
    outline: none;
  }
`;

const ReviewGridFlexWrapper = styled(Flex)`
  align-content: baseline;
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
`;

const StyledPreviewWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.grey0};
  position: fixed;
  top: ${NAVBAR_HEIGHT}px;
  right: ${({ theme }) => theme.dimensions.openSideNavWidth};
  bottom: 0;
  left: 0;
`;

const StyledBox = styled(Box)`
  width: 125px;
  height: 125px;
`;

const initialState: ReviewGridState<IllustrationEnriched> = {
  items: [],
  columns: 5,
  isFullScreenMode: false,
  selectedIdx: 0,
  scrollNeeded: false,
};

function ReviewGrid() {
  const [state, dispatch] = useReducer(
    reviewGridReducer<IllustrationEnriched>,
    initialState
  );
  const prefetchedBatch = useRef<IllustrationEnriched[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasPrefetchedData, setHasDataPrefetched] = useState(false);

  const { withAuthorizedInterceptor } = useAuthAPI();

  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedQueue = searchParams.get('queue');

  const fetchReviewBatch = useCallback(
    (requestParams: GetReviewBatchPayload, isPrefetching?: boolean) => {
      if (!isPrefetching) setLoading(true);

      withAuthorizedInterceptor(getReviewBatch(requestParams)).then((res) => {
        if (res) {
          if (!requestParams.batchId)
            navigate(
              `/review/batch/${res.id}${
                res.queue.id ? `?queue=${res.queue.id}` : ''
              }`
            );

          isPrefetching
            ? (prefetchedBatch.current = res.illustrations)
            : dispatch(setItems(res.illustrations));
        }

        if (!isPrefetching) setLoading(false);
      });
    },
    [navigate, withAuthorizedInterceptor]
  );

  useEffect(
    () => {
      const requestParams = {
        ...(params.batchId ? { batchId: parseInt(params.batchId, 10) } : null),
        ...(selectedQueue ? { queueId: parseInt(selectedQueue, 10) } : null),
      };
      fetchReviewBatch(requestParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const reviewCount = state.items.filter((item) => item.review).length;

  useEffect(() => {
    if (
      state.items.length > 0 &&
      reviewCount + 5 === state.items.length &&
      selectedQueue &&
      !hasPrefetchedData
    ) {
      // prefetch new batch on completion of current batch
      setHasDataPrefetched(true);
      fetchReviewBatch({ queueId: parseInt(selectedQueue, 10) }, true);
    } else if (
      state.items.length > 0 &&
      reviewCount === state.items.length &&
      selectedQueue
    ) {
      dispatch(setItems(prefetchedBatch.current));
      prefetchedBatch.current = [];
      setHasDataPrefetched(false);
    }
  }, [
    fetchReviewBatch,
    selectedQueue,
    reviewCount,
    state?.items.length,
    hasPrefetchedData,
  ]);

  const setRatingToStateAndSelectNextImage = (
    currentSelection: number,
    rating?: IllustrationRating
  ) => {
    dispatch({
      action: ReviewGridStateActions.SET_ITEM,
      parameter: {
        selectedIdx: currentSelection,
        rating,
      },
    });
  };

  const handleSelectGridItem = useCallback((idx: number) => {
    dispatch(setSelected(idx));
  }, []);

  const handleSetFullScreenMode = useCallback((isFullScreenMode: boolean) => {
    dispatch(setFullscreenMode(isFullScreenMode));
  }, []);

  const handleOnScroll = useCallback(
    () => dispatch(setIsScrollNeeded(false)),
    []
  );

  return (
    <ReviewGridFlexWrapper flexDirection="row">
      <StyledGrid p={3} cols={state.columns} tabIndex={0}>
        {loading ? (
          <Spinner />
        ) : (
          state.items.map(
            (illustration, i) =>
              typeof illustration === 'object' &&
              typeof illustration?.previewUrl === 'string' && (
                <ScrollIntoView
                  shouldScroll={i === state.selectedIdx && state.scrollNeeded}
                  onScroll={handleOnScroll}
                  block={'center'}
                  behavior={'smooth'}
                  key={i}>
                  <ReviewGridItem
                    isSelected={i === state.selectedIdx}
                    previewUrl={illustration.previewUrl}
                    onSelect={handleSelectGridItem}
                    idx={i}
                    rating={
                      typeof illustration.review === 'string'
                        ? illustration.review
                        : illustration.review?.value
                    }
                    setFullScreenMode={() => {
                      handleSelectGridItem(i);
                      handleSetFullScreenMode(true);
                    }}
                  />
                </ScrollIntoView>
              )
          )
        )}
        {state.isFullScreenMode ? (
          <StyledPreviewWrapper flexDirection="column" justifyContent="stretch">
            <FullPagePreview url={state.items[state.selectedIdx]?.previewUrl} />
            <Box flex="0 0 auto" bg="grey40">
              <Flex py="2" justifyContent="center">
                {state.items
                  .slice(
                    state.selectedIdx + 1,
                    state.selectedIdx + state.columns
                  )
                  .map(
                    (illustration, i) =>
                      typeof illustration === 'object' &&
                      typeof illustration.previewUrl === 'string' && (
                        <StyledBox key={illustration.id} p={2}>
                          <ReviewGridItem
                            previewUrl={illustration.previewUrl}
                            isSelected={false}
                            onSelect={handleSelectGridItem}
                            idx={i}
                            rating={
                              typeof illustration.review === 'string'
                                ? illustration.review
                                : illustration.review?.value
                            }
                          />
                        </StyledBox>
                      )
                  )}
              </Flex>
            </Box>
          </StyledPreviewWrapper>
        ) : null}
      </StyledGrid>

      <Sidebar
        dispatch={dispatch}
        illustration={state.items[state.selectedIdx]}
        fetchReviewBatch={() =>
          fetchReviewBatch({
            queueId: selectedQueue ? parseInt(selectedQueue, 10) : 1,
          })
        }
        setRatingToStateAndSelectNextImage={(rating) => {
          const currentSelection = state.selectedIdx;
          setRatingToStateAndSelectNextImage(currentSelection, rating);
        }}
      />
    </ReviewGridFlexWrapper>
  );
}

export default ReviewGrid;

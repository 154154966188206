import { useEffect } from 'react';

export function Login() {
  //Lets get ugly:
  useEffect(() => {
    if (window.location.hostname === 'localhost') {
      window.location.reload(); //local dev - reload to utilize the proxied page
    } else {
      const protocol = window.location.protocol;
      const host = window.location.hostname.substring(
        window.location.hostname.indexOf('.') + 1
      ); // navigate from ui.inspector.eyeem.com to inspector.eyeem.com
      window.location.assign(`${protocol}//${host}/login`);
    }
  }, []);

  return <span>Redirecting to login...</span>;
}

import styled from '@emotion/styled/macro';
import { Box } from '@eyeem-ui/atoms';

const StyledPreview = styled(Box)<{ url?: string }>`
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
`;

type FullPagePreviewProps = {
  url?: string;
};

const FullPagePreview = ({ url }: FullPagePreviewProps) => {
  return (
    <Box flex="1 1 auto">
      <StyledPreview url={url} />
    </Box>
  );
};

export default FullPagePreview;

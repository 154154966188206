import { getRequestOptions } from './util';
import { INSPECTOR_BASE_PATH } from './util/constants';
import { Fetch } from '../auth/types';

export type Queue = {
  id: number;
  name: string;
  count: number;
};

export type QueuesState = Queue[];

/**
 * Returns the available reviewing queues for illustrations along with the count of illustrations queued for review.
 * GET /api/review/illustration/queues
 *
 *  @returns {Promise<QueuesState>}
 *
 * [documentation]{@link https://github.com/eyeem/inspector/blob/integration/app/com/eyeem/inspector/controllers/docs/illustrations/README.md#apireviewillustrationqueues}
 */
export const getReviewQueues = (fetch: Fetch): Promise<QueuesState> => {
  return fetch(`${INSPECTOR_BASE_PATH.REVIEW}queues`, getRequestOptions())
    .then((response) => response.json())
    .catch((error) => console.error(error));
};

import { Dispatch, useCallback, useState } from 'react';
import { Flex, Button, Avatar, Text, Box, Tag } from '@eyeem-ui/atoms';
import styled from '@emotion/styled/macro';
import { IllustrationEnriched, IllustrationRating } from '@type';
import EmptySidebar from './EmptySidebar';
import { isEnrichedIllustration } from '@type/typeguards';
import { KEYCODES, NAVBAR_HEIGHT } from '@util/globals';
import { useEffect } from 'react';
import { handleKeyboardEvent, ReviewGridAction } from '../reducer';
import { shortcutKeyToRating } from '@util/shortcutToRating';
import { isRatingShortcut } from '@util/isRatingShortcut';
import { addIllustrationToOnHoldQueue, addReviewToIllustration } from '@api';

const SidebarFlex = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.grey40};
  width: ${({ theme }) => theme.dimensions.openSideNavWidth};
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
  border-left: 1px solid ${({ theme }) => theme.colors.ghost40};
`;

const StyledUser = styled(Flex)`
  gap: ${({ theme }) => theme.space[2]}px;
  align-items: center;
`;

const StyledBox = styled(Box)<{ centered?: boolean }>`
  text-align: ${({ centered }) => (centered ? 'center' : null)};
  border-radius: ${({ theme }) => theme.radii[2]};
`;

StyledBox.defaultProps = {
  mb: 1,
  bg: 'ghost50',
  p: 2,
};

const StyledMetaDataBox = styled(Box)`
  overflow: scroll;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ghost50};
`;

const StyledInlineBox = styled(Box)`
  display: inline-block;
`;

type ReviewGridSidebarProps = {
  illustration?: IllustrationEnriched | null;
  dispatch?: Dispatch<ReviewGridAction<any>>;
  fetchReviewBatch?: () => void;
  setRatingToStateAndSelectNextImage?: (
    rating: IllustrationRating | undefined
  ) => void;
};

const PositiveTag = ({ text }: { text: string }) => (
  <StyledInlineBox m={1}>
    <Tag variant="positive" text={text} />
  </StyledInlineBox>
);

const SectionBox = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => <Box mt={3}>{children}</Box>;

function ReviewGridSidebar({
  illustration,
  dispatch,
  fetchReviewBatch,
  setRatingToStateAndSelectNextImage,
}: ReviewGridSidebarProps) {
  const [localReviewState, setLocalReviewState] =
    useState<IllustrationRating>();

  const handleKeydown = useCallback(
    (event: globalThis.KeyboardEvent) => {
      event.stopPropagation();
      event.preventDefault();

      if (
        typeof illustration?.id === 'string' &&
        isRatingShortcut(event.code)
      ) {
        const rating = shortcutKeyToRating(event.code);
        if (rating === IllustrationRating.onHold) {
          try {
            addIllustrationToOnHoldQueue(illustration.id);

            // setRatingToStateAndSelectNextImage is passed from the reviewgrid, if we are in single asset review it is undefined and state should be maintained locally
            if (typeof setRatingToStateAndSelectNextImage !== 'undefined') {
              setRatingToStateAndSelectNextImage(rating);
            } else {
              setLocalReviewState(rating);
            }
          } catch (error) {
            if (typeof setRatingToStateAndSelectNextImage !== 'undefined') {
              setRatingToStateAndSelectNextImage(undefined);
            } else {
              setLocalReviewState(undefined);
            }
          }
        } else if (typeof rating !== 'undefined') {
          try {
            addReviewToIllustration(illustration.id, rating);

            // setRatingToStateAndSelectNextImage is passed from the reviewgrid, if we are in single asset review it is undefined and state should be maintained locally
            if (typeof setRatingToStateAndSelectNextImage !== 'undefined') {
              setRatingToStateAndSelectNextImage(rating);
            } else {
              setLocalReviewState(rating);
            }
          } catch (error) {
            if (typeof setRatingToStateAndSelectNextImage !== 'undefined') {
              setRatingToStateAndSelectNextImage(undefined);
            } else {
              setLocalReviewState(undefined);
            }
          }
        }
      } else if (event.code === KEYCODES.P) {
        if (typeof illustration?.creator?.webUrl === 'string') {
          const newTab = window.open(
            `${illustration.creator.webUrl}/illustrations`,
            '_blank'
          );
          newTab?.focus(); // * is blocked on Firefox and Safari
        }
      } else if (
        event.code === KEYCODES.ENTER &&
        event.ctrlKey &&
        typeof fetchReviewBatch !== 'undefined'
      ) {
        fetchReviewBatch();
      } else if (typeof dispatch !== 'undefined') {
        dispatch(handleKeyboardEvent(event));
      }
    },
    [fetchReviewBatch, illustration?.id, setRatingToStateAndSelectNextImage]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown);

    return () => window.removeEventListener('keydown', handleKeydown);
  }, [handleKeydown]);

  const reviewStatus =
    (
      localReviewState ??
      (typeof illustration?.review === 'string'
        ? illustration.review
        : illustration?.review?.value)
    )?.replaceAll('_', ' ') ?? 'Not Rated';

  return (
    <SidebarFlex
      flexDirection="column"
      justifyContent="space-between"
      py={3}
      px={2}>
      {illustration && isEnrichedIllustration(illustration) ? (
        <>
          <StyledMetaDataBox pb={3} mb={3}>
            <StyledUser flexDirection="row" mb={3}>
              <Avatar
                bg={'aqua50'}
                name={illustration.creator.fullname}
                thumbUrl={illustration.creator.thumbUrl}
              />
              <Flex flexDirection="column">
                <Text>{illustration.creator.nickname}</Text>
                <Text bold>{illustration.creator.id}</Text>
              </Flex>
            </StyledUser>
            <SectionBox>
              <Text bold>Illustration ID</Text>
              <StyledBox centered>
                <Text bold>{illustration.id}</Text>
              </StyledBox>
            </SectionBox>
            <SectionBox>
              <Text bold>Current Rating</Text>
              <StyledBox centered>
                <Text bold>{reviewStatus}</Text>
              </StyledBox>
            </SectionBox>
            <SectionBox>
              <Box>
                <Text bold>Caption</Text>
                <StyledBox>
                  <Text>{illustration.caption}</Text>
                </StyledBox>
              </Box>
              <Box>
                <Text bold>Keywords</Text>
                <StyledBox p="1">
                  {illustration.keywords?.map((tag) => (
                    <PositiveTag key={tag} text={tag} />
                  ))}
                </StyledBox>
              </Box>
              <Box>
                <Text bold>Styles</Text>
                <StyledBox p="1">
                  {illustration.styles?.map(({ key, value }) => (
                    <PositiveTag key={key} text={value} />
                  ))}
                </StyledBox>
              </Box>
              <Box>
                <Text bold>Categories</Text>
                <StyledBox p="1">
                  {illustration.categories?.map(({ key, value }) => (
                    <PositiveTag key={key} text={value} />
                  ))}
                </StyledBox>
              </Box>
            </SectionBox>
          </StyledMetaDataBox>
          <Flex justifyContent="center" alignItems="center">
            {fetchReviewBatch && (
              <Button onClick={() => fetchReviewBatch()}>
                Fetch Another Batch
              </Button>
            )}
          </Flex>
        </>
      ) : (
        <EmptySidebar />
      )}
    </SidebarFlex>
  );
}

export default ReviewGridSidebar;

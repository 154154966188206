import { ReviewBatchEnriched, ReviewBatchSingle } from '@type';
import { getRequestOptions } from './util';
import { INSPECTOR_BASE_PATH } from './util/constants';
import { getIllustrationsById } from './util/getIllustrationById';
import { Fetch } from '../auth/types';

/**
 * Creates a single review batch containing only the illustration with the provided id.
 * Sets review status of corresponding illustration IN_REVIEW.
 *
 * POST /api/review/illustration/:illustrationId
 *
 * @returns {Promise<ReviewBatch>}
 * [documentation]{@link https://github.com/eyeem/inspector/tree/integration/app/com/eyeem/inspector/controllers/docs/illustrations#post-apireviewillustrationillustrationid}
 */
export const createSingleImageReviewBatch = async (
  illustrationId: number
): Promise<ReviewBatchEnriched | void> => {
  try {
    const response = await fetch(
      `${INSPECTOR_BASE_PATH.REVIEW}${illustrationId}`,
      getRequestOptions('POST')
    );

    if (!response.ok) {
      const { message } = await response.json();
      throw message;
    }

    const reviewBatch: ReviewBatchSingle = await response.json();

    const illustrations = await getIllustrationsById([
      reviewBatch.illustrationId,
    ]);

    if (illustrations) {
      return { ...reviewBatch, illustrations: [...illustrations] };
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

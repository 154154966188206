import { Author } from '@type';
import { INSPECTOR_BASE_PATH } from './constants';

export const getUserById = async (id: number): Promise<Author | void> => {
  try {
    const userResponse = await fetch(INSPECTOR_BASE_PATH.AUTHOR + id, {
      credentials: 'include',
    });

    return await userResponse.json();
  } catch (error) {
    return console.error(error);
  }
};

import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import SingleAssetReview from '@components/singleAssetReview';
import Home from './components/home';
import ReviewGrid from './components/reviewGrid';
import Navbar from '@components/navbar';
import { AuthContextProvider, useAuthState } from './auth/context';
import { useEffect } from 'react';
import { Login } from '@components/Login';
import Version from '@components/Version';

function AuthorizedApp() {
  const { isAuthorized } = useAuthState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/login', { replace: true });
    }
  }, [isAuthorized, navigate]);

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="review" element={<ReviewGrid />} />
        <Route path="review/illustration/:id" element={<SingleAssetReview />} />
        <Route path="review/batch/:batchId" element={<ReviewGrid />} />
        <Route path="login" element={<Login />} />
        <Route path="version" element={<Version />} />
      </Routes>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <AuthorizedApp />
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;

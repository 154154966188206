import { memo } from 'react';
import styled from '@emotion/styled/macro';
import { IconButton } from '@eyeem-ui/atoms';
import { Search } from '@eyeem-ui/icons';

import { IllustrationRating } from '@type';
import StatusIcon from './StatusIcon';

type StyledGridItemProps = {
  isSelected: boolean;
  previewUrl: string;
};

const StyledGridItem = styled.div<StyledGridItemProps>`
  position: relative;
  border-radius: ${({ theme }) => theme.radii[2]};
  background-image: url(${({ previewUrl }: { previewUrl: string }) =>
    previewUrl});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 1;
  max-height: 100vh;
  border: ${({ isSelected, theme }) =>
    isSelected ? `3px solid ${theme.colors.aqua60}` : '3px none white'};
  box-shadow: ${({ isSelected }) => isSelected && ' 5px 5px 15px -5px #676767'};
  transform: ${({ isSelected }) => isSelected && 'translateY(-2px)'};
  transition: all 0.2s ease-in-out;
  & :hover {
    background-color: ${({ theme }) => theme.colors.ghost40};
  }
`;

const StyledSearchIconButton = styled(IconButton)`
  position: absolute;
  bottom: ${({ theme }) => theme.space[1]}px;
  right: ${({ theme }) => theme.space[1]}px;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  ${StyledGridItem}:hover & {
    opacity: 1;
  }
`;

type ReviewGridItemProps = {
  previewUrl: string;
  isSelected: boolean;
  onSelect: (idx: number) => void;
  rating?: IllustrationRating;
  idx: number;
  setFullScreenMode?: () => void;
};

const ReviewGridItem = ({
  isSelected,
  onSelect,
  previewUrl,
  rating,
  idx,
  setFullScreenMode,
}: ReviewGridItemProps) => {
  return (
    <StyledGridItem
      previewUrl={previewUrl}
      isSelected={isSelected}
      onClick={() => onSelect(idx)}>
      <StatusIcon illustrationRating={rating} />

      <StyledSearchIconButton
        variant="secondary"
        icon={Search}
        onClick={(e: Event) => {
          e.stopPropagation();
          if (setFullScreenMode) {
            setFullScreenMode();
          }
        }}
      />
    </StyledGridItem>
  );
};

export default memo(ReviewGridItem);

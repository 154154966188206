import { Box, Flex } from '@eyeem-ui/atoms';
import Queues from './Queues';
import SingleAssetReview from './SingleAssetReviewInput';

const Home = () => {
  return (
    <Flex justifyContent="center" m={3}>
      <Box>
        <Queues />
        <Box>
          <SingleAssetReview />
        </Box>
      </Box>
    </Flex>
  );
};

export default Home;

import { InfoBox } from '@eyeem-ui/organisms';
import { Box } from '@eyeem-ui/atoms';
import styled from '@emotion/styled';

const StyledBox = styled(Box)`
  margin: auto;
`;
const EmptySidebar = () => {
  return (
    <StyledBox p={2}>
      <InfoBox text="Select an image to see its metadata" />
    </StyledBox>
  );
};

export default EmptySidebar;

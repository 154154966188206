import { illustrationBasicSchema, illustrationEnrichedSchema } from '@type/zod';
import { IllustrationBasic, IllustrationEnriched } from '@type';

export const isBasicIllustration = (arg: unknown): arg is IllustrationBasic => {
  return Boolean(illustrationBasicSchema.parse(arg));
};

export const isEnrichedIllustration = (
  arg: unknown
): arg is IllustrationEnriched => {
  return Boolean(illustrationEnrichedSchema.parse(arg));
};

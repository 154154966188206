import { ReviewBatchBasic, ReviewBatchEnriched } from '@type';
import { getRequestOptions } from './util';
import { INSPECTOR_BASE_PATH } from './util/constants';

import { getIllustrationsById } from './util/getIllustrationById';
import { isBasicReviewBatch } from '@type/typeguards/isReviewBatch';
import { Fetch } from '../auth/types';

export type CreateReviewBatchPayload = {
  queueId: number;
};

/**
 * Creates review batches.
 *
 * Creates an illustration review batch from the Queue identified by queueId, bound to the user
 * identified by the cookie.
 *
 * POST /api/review/illustration/batches
 *
 * @returns Promise
 *
 * [documentation]{@link https://github.com/eyeem/inspector/blob/integration/app/com/eyeem/inspector/controllers/docs/illustrations/README.md#apireviewillustrationbatches}
 */
export const createReviewBatch =
  (payload: CreateReviewBatchPayload) =>
  async (fetch: Fetch): Promise<ReviewBatchEnriched | void> => {
    const body = JSON.stringify(payload);
    try {
      const response = await fetch(`${INSPECTOR_BASE_PATH.REVIEW}batches`, {
        credentials: 'include',
        ...getRequestOptions('POST'),
        body,
      });

      const reviewBatch: ReviewBatchBasic = await response.json();

      if (!isBasicReviewBatch(reviewBatch)) {
        return console.error('not valid reviewBatch type', reviewBatch);
      }

      const illustrations = await getIllustrationsById(
        reviewBatch.illustrations
      );
      if (illustrations) {
        return { ...reviewBatch, illustrations: [...illustrations] };
      }
    } catch (error) {
      console.error(error);
    }
  };

import { KeyboardEvent } from 'react';
import { KEYCODES } from '@util/globals';
import { isBasicIllustration, isEnrichedIllustration } from '@type/typeguards';
import { IllustrationBasic } from '@type';
import { IllustrationEnriched } from '@type';

export type ReviewGridState<T> = {
  items: Array<T>;
  selectedIdx: number;
  columns: number;
  isFullScreenMode: boolean;
  scrollNeeded: boolean;
};

export enum ReviewGridStateActions {
  'KEYBOARD_EVENT',
  'MOVE_POSITION_BY',
  'NAVIGATE',
  'RATE_IMAGE',
  'SET_COLUMN_SIZE',
  'SET_FULL_SCREEN_MODE',
  'SET_ITEM',
  'SET_ITEMS',
  'SET_SCROLL_NEEDED',
  'SET_SELECTED',
  'TOGGLE_FULLSCREEN_MODE',
}

export type ReviewGridAction<T> = {
  action: ReviewGridStateActions;
  parameter: T;
};

const makeAction = <T>(action: ReviewGridStateActions, parameter: T) => ({
  action,
  parameter: parameter,
});

export const setItems = <T>(items: Array<T>) =>
  makeAction(ReviewGridStateActions.SET_ITEMS, { items });
export const setColumns = (columns: number) =>
  makeAction(ReviewGridStateActions.SET_COLUMN_SIZE, { columns });
export const navigate = (event: KeyboardEvent) =>
  makeAction(ReviewGridStateActions.NAVIGATE, { event });
export const setSelected = (idx: number) =>
  makeAction(ReviewGridStateActions.SET_SELECTED, { idx });
export const setFullscreenMode = (isFullScreenMode: boolean) =>
  makeAction(ReviewGridStateActions.SET_FULL_SCREEN_MODE, { isFullScreenMode });
export const handleKeyboardEvent = (event: globalThis.KeyboardEvent) =>
  makeAction(ReviewGridStateActions.KEYBOARD_EVENT, { event });
export const toggleFullScreenMode = makeAction(
  ReviewGridStateActions.TOGGLE_FULLSCREEN_MODE,
  {}
);
export const setIsScrollNeeded = (isScrollNeeded: boolean) =>
  makeAction(ReviewGridStateActions.SET_SCROLL_NEEDED, { isScrollNeeded });

const movePositionBy = (amount: number) =>
  makeAction(ReviewGridStateActions.MOVE_POSITION_BY, { amount });

const keyboardEventToAction = (
  event: KeyboardEvent
):
  | {
      action: ReviewGridStateActions;
      parameter: Record<string, unknown>;
    }
  | undefined => {
  switch (event.code) {
    case KEYCODES.ESCAPE:
      return setFullscreenMode(false);
    case KEYCODES.SPACE:
      return toggleFullScreenMode;
    case KEYCODES.ARROW_LEFT:
    case KEYCODES.ARROW_UP:
    case KEYCODES.ARROW_DOWN:
    case KEYCODES.ARROW_RIGHT:
      return navigate(event);

    default:
  }
};

const isRowJump = (
  previousPosition: number,
  currentPosition: number,
  columns: number
): boolean => {
  const previousRow = Math.floor(previousPosition / columns);
  const currentRow = Math.floor(currentPosition / columns);
  return previousRow !== currentRow;
};

const determineNavigation = (code: string, columns: number): number => {
  switch (code) {
    case KEYCODES.ARROW_LEFT:
      return -1;
    case KEYCODES.ARROW_RIGHT:
      return 1;
    case KEYCODES.ARROW_UP:
      return columns * -1;
    case KEYCODES.ARROW_DOWN:
      return columns;
    default:
      return 0;
  }
};

const moveIndex = (
  currentPosition: number,
  steps: number,
  length: number
): number => {
  const result = currentPosition + steps;
  return result < 0 || result >= length ? currentPosition : result;
};

export const reviewGridReducer = <T>(
  state: ReviewGridState<T>,
  action: ReviewGridAction<any>
): ReviewGridState<T> => {
  switch (action.action) {
    case ReviewGridStateActions.KEYBOARD_EVENT: {
      const keyboardEventAction = keyboardEventToAction(action.parameter.event);

      if (typeof keyboardEventAction !== 'undefined') {
        return reviewGridReducer(state, keyboardEventAction);
      }
      return state;
    }

    case ReviewGridStateActions.MOVE_POSITION_BY: {
      const newIdx = moveIndex(
        state.selectedIdx,
        action.parameter.amount,
        state.items.length
      );
      return {
        ...state,
        selectedIdx: newIdx,
        scrollNeeded: isRowJump(state.selectedIdx, newIdx, state.columns),
      };
    }

    case ReviewGridStateActions.NAVIGATE: {
      const jump = determineNavigation(
        action.parameter.event.code,
        state.columns
      );
      return reviewGridReducer(state, movePositionBy(jump));
    }

    case ReviewGridStateActions.SET_COLUMN_SIZE: {
      const legalColumnCount = (columns: number) =>
        columns > 0 && columns <= 10;
      const columns = legalColumnCount(action.parameter.columns)
        ? action.parameter.columns
        : state.columns;
      return { ...state, columns };
    }

    case ReviewGridStateActions.SET_FULL_SCREEN_MODE: {
      const hasSelection = state.items[state.selectedIdx] !== undefined;
      return {
        ...state,
        isFullScreenMode: hasSelection && action.parameter.isFullScreenMode,
      };
    }

    case ReviewGridStateActions.SET_ITEM: {
      const updatedItems = state.items.reduce((acc, cur) => {
        if (isBasicIllustration(cur) || isEnrichedIllustration(cur)) {
          return [...acc, cur];
        } else {
          return acc;
        }
      }, [] as (IllustrationBasic | IllustrationEnriched)[]);
      updatedItems[action.parameter.selectedIdx].review =
        action.parameter.rating;
      const newState = {
        ...state,
        ...updatedItems,
      };
      const wasMovedAlready =
        action.parameter.selectedIdx !== state.selectedIdx;
      return reviewGridReducer(
        newState,
        movePositionBy(wasMovedAlready ? 0 : 1)
      );
    }

    case ReviewGridStateActions.SET_ITEMS: {
      return {
        ...state,
        items: action.parameter.items,
        selectedIdx: 0,
      };
    }

    case ReviewGridStateActions.SET_SCROLL_NEEDED: {
      return {
        ...state,
        scrollNeeded: action.parameter.isScrollNeeded,
      };
    }

    case ReviewGridStateActions.SET_SELECTED: {
      return {
        ...state,
        selectedIdx: action.parameter.idx,
      };
    }

    case ReviewGridStateActions.TOGGLE_FULLSCREEN_MODE: {
      return reviewGridReducer(
        state,
        setFullscreenMode(!state.isFullScreenMode)
      );
    }

    default:
      return state;
  }
};

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Flex } from '@eyeem-ui/atoms';
import { createSingleImageReviewBatch } from '@api';
import FullPagePreview from '@components/fullPagePreview';
import { ReviewBatchEnriched } from '@type';
import ReviewGridSidebar from '@components/reviewGrid/sidebar/ReviewGridSidebar';
import NotFound from '@components/notFound';

const SingleAssetReview = (props: any) => {
  const params = useParams();
  const [reviewBatch, setReviewBatch] = useState<ReviewBatchEnriched>();
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (params.id) {
      createSingleImageReviewBatch(parseInt(params.id, 10))
        .then((response) => {
          if (response) setReviewBatch(response);
        })
        .catch(setError);
    }
  }, []);

  const illustration = reviewBatch?.illustrations[0];
  if (!illustration) return <NotFound message={error} />;

  return (
    <Flex>
      <FullPagePreview url={illustration.previewUrl} />
      <ReviewGridSidebar illustration={illustration} />
    </Flex>
  );
};

export default SingleAssetReview;

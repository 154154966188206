// Generated by ts-to-zod
import { z } from 'zod';
import { IllustrationRating, ReviewStatus } from './../index';

export const coverPhotoSchema = z.object({
  blacklisted: z.boolean(),
  file_id: z.string(),
  height: z.number(),
  hidden: z.boolean(),
  id: z.string(),
  photoUrl: z.string(),
  photoUrlPublic: z.string(),
  submittedToMarket: z.boolean(),
  type: z.string(),
  updated: z.string(),
  webUrl: z.string(),
  width: z.number(),
});

export const illustrationRatingSchema = z.nativeEnum(IllustrationRating);

export const marketTotalsSchema = z.object({
  commercial: z.number(),
  editorial: z.number(),
  getty: z.number(),
  onHold: z.number(),
  partner: z.number(),
});

export const reviewBatchBasicSchema = z.object({
  createdAt: z.string(),
  id: z.number(),
  illustrations: z.array(z.string()),
  queue: z.object({
    id: z.number(),
    name: z.string(),
    isVisible: z.boolean(),
    isDeleted: z.boolean(),
  }),
  reviewerId: z.number(),
});

export const reviewBatchSingleSchema = reviewBatchBasicSchema
  .omit({ illustrations: true })
  .and(
    z.object({
      illustrationId: z.string(),
    })
  );

export const reviewStatusSchema = z.nativeEnum(ReviewStatus);

export const styleSchema = z.object({
  key: z.string(),
  value: z.string(),
});

export const categorySchema = styleSchema;

export const authorSchema = z.object({
  blacklisted: z.boolean(),
  blocked: z.boolean(),
  coverPhoto: coverPhotoSchema.nullable(),
  description: z.string().nullable(),
  file_id: z.string().nullable(),
  follower: z.boolean(),
  following: z.boolean(),
  fullname: z.string(),
  hidden: z.boolean(),
  homepageUrl: z.string().nullable(),
  id: z.string(),
  marketTotals: marketTotalsSchema,
  nickname: z.string(),
  photoUrl: z.string(),
  restricted: z.boolean(),
  thumbUrl: z.string(),
  totalFollowers: z.number(),
  totalFriends: z.number(),
  totalLikedAlbums: z.number(),
  totalLikedPhotos: z.number(),
  totalPhotos: z.number(),
  webUrl: z.string(),
});

export const reviewSchema = z.object({
  status: z.string(),
  value: illustrationRatingSchema,
  description: z.null(),
});

export const illustrationBasicSchema = z.object({
  caption: z.string(),
  creator: z.object({
    id: z.union([z.number(), z.string()]),
  }),
  categories: z.array(categorySchema).optional(),
  createdAt: z.string(),
  height: z.number(),
  id: z.string(),
  isLicensable: z.boolean(),
  keywords: z.array(z.string()).optional(),
  previewUrl: z.string().optional(),
  review: z.union([reviewSchema, illustrationRatingSchema]).optional(),
  styles: z.array(styleSchema).optional(),
  thumbUrl: z.string().optional(),
  width: z.number(),
});

export const illustrationEnrichedSchema = illustrationBasicSchema
  .omit({ creator: true })
  .and(
    z.object({
      creator: authorSchema,
    })
  );

export const reviewBatchEnrichedSchema = reviewBatchBasicSchema
  .omit({ illustrations: true })
  .and(
    z.object({
      illustrations: z.array(illustrationEnrichedSchema),
    })
  );

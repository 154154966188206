type Method = 'GET' | 'POST' | 'PUT';
type Redirect = 'follow';
type Mode = 'cors' | 'no-cors';

/**
 * returns request initOptions object
 * @param method?: 'GET' | 'POST';
 * @param redirect?: 'follow';
 * @param mode?: 'cors';
 
 * @returns RequestInit
 */
export const getRequestOptions = (
  method: Method = 'GET',
  redirect: Redirect = 'follow',
  mode: Mode = 'cors'
): RequestInit => {
  return {
    method,
    redirect,
    mode,
    ...(method === 'POST' && {
      headers: new Headers({ 'content-type': 'application/json' }),
    }),
  };
};

export const NAVBAR_HEIGHT = 56;

// keyboard event code
export enum KEYCODES {
  ARROW_DOWN = 'ArrowDown',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_UP = 'ArrowUp',
  DIGIT0 = 'Digit0',
  DIGIT1 = 'Digit1',
  DIGIT2 = 'Digit2',
  DIGIT3 = 'Digit3',
  DIGIT4 = 'Digit4',
  DIGIT5 = 'Digit5',
  DIGIT7 = 'Digit7',
  DIGIT8 = 'Digit8',
  DIGIT9 = 'Digit9',
  E = 'KeyE',
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  H = 'KeyH',
  NUMPAD0 = 'Numpad0',
  NUMPAD1 = 'Numpad1',
  NUMPAD2 = 'Numpad2',
  NUMPAD3 = 'Numpad3',
  NUMPAD4 = 'Numpad4',
  NUMPAD5 = 'Numpad5',
  NUMPAD7 = 'Numpad7',
  NUMPAD8 = 'Numpad8',
  NUMPAD9 = 'Numpad9',
  NUMPADDECIMAL = 'NumpadDecimal',
  P = 'KeyP',
  PERIOD = 'Period',
  SPACE = 'Space',
}

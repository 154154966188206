import { reviewBatchBasicSchema, reviewBatchEnrichedSchema } from '@type/zod';
import { ReviewBatchBasic, ReviewBatchEnriched } from '@type';

export const isBasicReviewBatch = (arg: unknown): arg is ReviewBatchBasic => {
  return Boolean(reviewBatchBasicSchema.parse(arg));
};
export const isEnrichedReviewBatch = (
  arg: unknown
): arg is ReviewBatchEnriched => {
  return Boolean(reviewBatchEnrichedSchema.parse(arg));
};
